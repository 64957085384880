import '@/shared/utils/validators/yup';

import { foundationTheme } from '@grupoboticario/flora';
import { Box, FloraProvider } from '@grupoboticario/flora-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserLayout } from './pages/Users';

export function App() {
  return (
    <FloraProvider>
      <Box className={`${foundationTheme}`} data-testid="app">
        <BrowserRouter basename="admin-users">
          <Routes>
            <Route path="/usuarios/*" element={<UserLayout />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </FloraProvider>
  );
}
