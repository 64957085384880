import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';

const ListUsersPage = loadable(() => import('./ListUsers'), {
  resolveComponent: components => components.ListUsers,
});
const CreateUserPage = loadable(() => import('./CreateUser'), {
  resolveComponent: components => components.CreateUser,
});

export function UserLayout() {
  return (
    <Routes>
      <Route path="criar" element={<CreateUserPage />} />
      <Route index element={<ListUsersPage />} />
    </Routes>
  );
}
